<script setup>
const cookie = useCookie('color-scheme');

useHead({
  htmlAttrs: {
    class: cookie.value === 'dark' ? 'dark' : '',
  },
});

const el = ref(); // used for resizeObserver
const { showScreenSizeOverlay } = storeToRefs(useBaseStore());

const { top, right, bottom, left } = useScreenSafeArea();

useResizeObserver(el, entries => {
  const entry = entries[0];
  const { width, height } = entry.contentRect;
  if (import.meta.env.NODE_ENV === 'production') {
    if (width < 1280 || height < 1000) {
      showScreenSizeOverlay.value = true;
    } else showScreenSizeOverlay.value = false;
  }
});
</script>

<template>
  <v-app
    id="inspire"
    ref="el"
  >
    <v-sheet class="relative w-full h-full md:h-screen md:min-h-screen">
      <div class="py-8 mx-auto md:absolute py-md-0 w-60 xl:w-[450px] top-8 md:left-12 md:top-12">
        <ElementTheLogo />
      </div>
      <slot />
      <ElementNotivue />
    </v-sheet>
    <ClientOnly>
      <ElementScreenSizeOverlay />
    </ClientOnly>
  </v-app>
</template>
